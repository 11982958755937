import React from 'react';
import { toast } from 'react-toastify';
import axiosInstance, { axiosRefresh } from '../../Core/AxiosInstance/axiosInstance';
import { useAppDispatch, useAppSelector } from '../../Core/Store/hooks';
import { signOut } from '../State/authSlice/authSlice';
import { selectEvent } from '../State/events/eventSlice';

const Interceptors = () => {
  const dispatch = useAppDispatch();
  const event = useAppSelector(selectEvent);

  /**
   * @todo intercept any request and add the token to every request
   * @return the request with the token
   */
  axiosInstance.interceptors.request.use(
    function (config: any) {
      //check if iam not in login page
      if (config.url !== '/login') {
        const localStorageToken = localStorage.getItem('token');
        //add the token from local storage to the header request
        config.headers.Authorization = localStorageToken
          ? `Bearer ${JSON.parse(localStorageToken).access_token}`
          : '';
      }
      //return the request with the token
      return config;
    },
    (error: any) => {
      //if err don't do any thing and i will handel it in my global handel error
      return Promise.reject(error);
    }
  );

  /**
   * @todo intercept any request and add the refresh token when the token is expired
   * @return the request with the refresh token
   */
  axiosRefresh.interceptors.request.use(function (config: any) {
    if (config.url !== '/login') {
      const token = localStorage.getItem('refreshToken');

      config.headers.Authorization = token
        ? `Bearer ${JSON.parse(token).access_token}`
        : '';
    }
    return config;
  });

  /**
   * @todo intercept any request and handel the global errors and show the right toaster
   * @return the error promise
   */
  axiosInstance.interceptors.response.use(
    (res: any) => {
      return res;
    },
    async (err: any) => {
      if (err.response) {
        //when the Access Token is expired
        if (err.response.status === 401) {
          // if the refresh token expired clear the local storage and navigate to login
          toast.error(err.response.data.message, {
            position: toast.POSITION.TOP_CENTER,
            toastId: 'uniqueId',
          });
          localStorage.clear();
          window.location.assign(event?.login_url);
          dispatch(signOut());
          return Promise.reject(err);
        }
        // with validations errors show toasters
        if (err.response.status === 422) {
          if (err.response.data.errors) {
            for (const key in err.response.data.errors) {
              const element = err.response.data.errors[key];
              for (const errMessage of element) {
                toast.error(errMessage, {
                  position: toast.POSITION.TOP_CENTER,
                  toastId: 'uniqueId',
                });
              }
            }
          } else if (err.response.data.data.message) {
            toast.error(err.response.data.data.message, {
              position: toast.POSITION.TOP_CENTER,
              toastId: 'uniqueId',
            });
          }
        }
        // else just show error message with
        // validations errors
        if (err.response.status === 403) {
          toast.error(err.response.data.message, {
            position: toast.POSITION.TOP_CENTER,
            toastId: 'uniqueId',
          });
        }
        if (err.response.status === 429) {
          toast.error(err.response.data.message, {
            position: toast.POSITION.TOP_CENTER,
            toastId: 'uniqueId',
          });
        }
        // servers error
        if (err.response.status === 500) {
          toast.error('something went wrong \n please contact the IT', {
            position: toast.POSITION.TOP_CENTER,
            toastId: 'uniqueId',
          });
        }
        //not found
        if (err.response.status === 404) {
          // console.log(err.response.data.message)
          if (err.response.data.message) {
            // localStorage.clear();
            toast.error(err.response.data.message, {
              position: toast.POSITION.TOP_CENTER,
              toastId: 'uniqueId',
            });
          } else {
            toast.error('something went wrong \n please contact the IT', {
              position: toast.POSITION.TOP_CENTER,
              toastId: 'uniqueId',
            });
          }
        }
        //internet and fire wall errors
        if (err.response.status === 0) {
          toast.error('no Internet connection \n please contact the IT', {
            position: toast.POSITION.TOP_CENTER,
            toastId: 'uniqueId',
          });
        } else {
          toast.error('something went wrong \n please contact the IT', {
            position: toast.POSITION.TOP_CENTER,
            toastId: 'uniqueId',
          });
        }
      }

      return Promise.reject(err);
    }
  );
  return <></>;
};

export default Interceptors;
