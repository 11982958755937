import React, { Suspense } from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import { GlobalStyle } from './App.style';

import './App.css';
import Loading from './Components/Loading/Loading';
import Routes from './Core/Routes/Routes';
import Interceptors from './Service/Interceptors/Interceptors';

function App() {
  return (
    <Suspense fallback={<Loading />}>
      <Router>
        <Routes />
        <GlobalStyle />
        <Interceptors />
        <ToastContainer limit={3} />
      </Router>
    </Suspense>
  );
}

export default App;
