import styled from 'styled-components';

export const Warper: any = styled.div`
  background-color: #005f96;
  height: 100vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  .header {
    font-size: calc(3vw + 1rem);
  }
  .message,
  .team,
  .header,
  h2 {
    color: #fff;
    text-align: center;
  }
  img {
    max-height: 50%;
  }
`;
