import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { RootState } from '../../../Core/Store/store';
import { fetchEvents } from '../../../Pages/Home/home.api';

export interface IEvent {
  data: any;
}

const initialState: IEvent = {
  data: {},
};

export const addEventThunk = createAsyncThunk(
  'event/fetchEvents',
  async (eventHash: string) => {
    const res = await fetchEvents(eventHash);
    return res;
  }
);

/**
 * @todo
 * create my reducer && set actions to it
 *
 */
export const eventSlice = createSlice({
  name: 'event',
  initialState,
  reducers: {
    // when sign in get my data to my reducer
    addEvent: (state, action) => {
      state.data = { ...action.payload };
    },
    // when sign out return my state to initial state
    resetEvent: () => {
      return initialState;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(addEventThunk.fulfilled, (state, action) => {
      // console.log(action.payload);
      state.data = { ...action.payload };
    });
  },
});

// export my actions to dispatch hook
export const { addEvent, resetEvent } = eventSlice.actions;

// get my auth object from my selector hook
export const selectEvent = (state: RootState) => state.event.data;

// export reducer to my state
export default eventSlice.reducer;
