import { createSlice } from '@reduxjs/toolkit';
import { RootState } from '../../../Core/Store/store';

export interface IPages {
  data: any;
}

const initialState: IPages = {
  data: {},
};

/**
 * @todo
 * create my reducer && set actions to it
 *
 */
export const pagesSlice = createSlice({
  name: 'pages',
  initialState,
  reducers: {
    // when sign in get my data to my reducer
    addPages: (state, action) => {
      state.data = { ...action.payload };
    },
    // when sign out return my state to initial state
    resetPages: () => {
      return initialState;
    },
  },
});

// export my actions to dispatch hook
export const { addPages, resetPages } = pagesSlice.actions;

// get my auth object from my selector hook
export const selectPages = (state: RootState) => state.pages.data;

// export reducer to my state
export default pagesSlice.reducer;
