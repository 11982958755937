import React, { useEffect } from 'react';
import { Warper } from './EventEnded.style';
import axiosInstance from '../../Core/AxiosInstance/axiosInstance';

const EventEnded = () => {
  const eventHash = window.location.href
    .split('/event-ended')[0]
    .split('events/')[1];

  const validateEventStatus = async () => {
    const res = await axiosInstance.get(`/verify-hash/${eventHash}`);
    if (res.data.data.status !== 1) window.history.back();
  };

  useEffect(() => {
    validateEventStatus();
  }, []);

  return (
    <Warper>
      <h1 className="header">Event Has Ended</h1>
      <h2>We&rsquo;ll be back soon!</h2>
      <div>
        <p className="message">
          Sorry for the inconvenience. We will be back soon with new Events.
        </p>
        <p className="team">&mdash; The [Caduceus Lane] Team</p>
      </div>
    </Warper>
  );
};

export default EventEnded;
