import React, { useEffect } from 'react';
import { Warper } from './UnderMaintenance.style';
import { io, Socket } from 'socket.io-client';
import { useAppSelector } from '../../Core/Store/hooks';
import { selectEvent } from '../../Service/State/events/eventSlice';
import { useNavigate } from 'react-router-dom';
import { selectAuth } from '../../Service/State/authSlice/authSlice';

const UnderMaintenance = () => {
  let socket: Socket;
  const event = useAppSelector(selectEvent);
  const user = useAppSelector(selectAuth);
  const eventHash = event?.hash;
  const token: any = localStorage.getItem('token');
  const navigate = useNavigate();

  useEffect(() => {
    // eslint-disable-next-line react-hooks/exhaustive-deps
    socket = io(`${process.env.REACT_APP_IO_URL}`, {
      reconnectionDelayMax: 10000,
      auth: {
        token: token ? JSON.parse(token).access_token : '',
      },
    });

    //listen on reconnect request
    socket.on(`event-${eventHash}`, (message) => {
      // console.log(message);
      if (message === 'event updated') {
        // window.history.back();
        navigate(`/events/${eventHash}?email=${user.email[0]}`);
      }
    });

    // if (!event?.time?.start_date) window.history.back();

    return () => {
      socket.disconnect();
    };
  }, []);

  return (
    <Warper>
      <h1 className="header">We&rsquo;ll be back soon!</h1>
      <h2>Event Under Maintenance</h2>
      <div>
        <p className="message">
          Sorry for the inconvenience. We&rsquo;re performing some maintenance at the
          moment.
        </p>
        <p className="team">&mdash; The [Caduceus Lane] Team</p>
      </div>
    </Warper>
  );
};

export default UnderMaintenance;
