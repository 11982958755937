import React, { lazy } from 'react';
import { useRoutes } from 'react-router-dom';
import UnderMaintenance from '../../Pages/UnderMaintenance/UnderMaintenance';
import EventEnded from './../../Pages/EventEnded/EventEnded';

const Routes = () => {
  const Home = lazy(() => import('../../Pages/Home/Home'));
  const NotFound = lazy(() => import('../../Pages/NotFound/NotFound'));

  let eventHash =
    window.location.href.split('/login')[0].split('events/')[1] ||
    localStorage.getItem('hash') ||
    '';

  const routes = useRoutes([
    {
      path: '/events/:event',
      element: <Home />,
    },
    {
      path: '/events/:event/under-maintenance',
      element: <UnderMaintenance />,
    },
    {
      path: '/events/:event/event-ended',
      element: <EventEnded />,
    },
    {
      path: '/',
      element: <NotFound />,
    },
    { path: '*', element: <NotFound /> },
  ]);
  return routes;
};

export default Routes;
