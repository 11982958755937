import { createGlobalStyle } from 'styled-components';
{/* <style>
    @import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;700&display=swap');
</style> */}
export const GlobalStyle = createGlobalStyle`
    :root{
        --primary-color:#B1080E;
        --hover-color:#cd2026 ;
        --primary-border-color:#cd202645;
        --focus-ring:#cd202645;
        --background-color:#fff;
        --secondary-background-color:rgba(255, 255, 255, 0.33);
        --secondary-border:rgb(112, 112, 112,.33);
        --body-color:#F5F5F5;
        --select-color:#E9E9E9;
        --success-color:#3A9E52;
        --info-color:##F29423;
        

    }
    *{
        padding: 0;
        margin: 0;
    }
    html{
        font-size: 16px;
        font-family: 'Nexa-light';
        box-sizing: border-box;
    }
    body {
        box-sizing: border-box;
        background-color: var(--background-color);
    }
    .p-panelmenu-header-link {
        background-color:  var(--primary-color) !important;
        color: #fff !important;
        border-radius: 0px 8px 8px 0px !important;
        height: 80px !important;
    }
    .p-panelmenu-header-link:hover {
        background-color: var(--hover-color) !important;
    }
    .p-menuitem{
        min-height: 40px;
    }
    .p-checkbox .p-checkbox-box{
        border-radius: 5px !important;
        width: 21px;
        height: 21px;
        border-color: rgba(166, 166, 166, 0.5) !important;

    }
    .p-component{
        font-family: 'Nexa-light';
    }

`;
