import React from 'react';

// Images
import LogoImage from '../../Assets/logo2x.png';
import { Root } from './Loading.Style';

const Loading = () => {
  return (
    <Root>
      <img src={LogoImage} alt="" />
    </Root>
  );
};

export default Loading;
