import { configureStore, ThunkAction, Action } from '@reduxjs/toolkit';
// import logger from 'redux-logger';
import spinnerReducer from '../../Service/State/spinnerSlice/spinnerSlice';
import authReducer from '../../Service/State/authSlice/authSlice';
import userInfoReducer from '../../Service/State/userInfoSlice/userInfoSlice';
import toastReducer from '../../Service/State/toasterSlice/toasterSlice';
import eventReducer from '../../Service/State/events/eventSlice';
import pagesReducer from '../../Service/State/pages/pagesSlice';

export const store = configureStore({
  reducer: {
    spinner: spinnerReducer,
    auth: authReducer,
    userInfo: userInfoReducer,
    toast: toastReducer,
    event: eventReducer,
    pages: pagesReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
  // .concat(logger),
  devTools: process.env.NODE_ENV !== 'production',
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
