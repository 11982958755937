import { createSlice } from '@reduxjs/toolkit';
import { RootState } from '../../../Core/Store/store';
import { IUserInfo } from './userInfo.interface';

//initial state for user info
const initialState: IUserInfo = {
  name: '',
  role: '',
  permissions: [],
  specification: '',
};

/**
 * @todo
 * create my reducer && set actions to it
 *
 */
export const userInfoSlice = createSlice({
  name: 'info',
  initialState,
  reducers: {
    /// set the user info
    setUserInfo: (state, action) => {
      state = action.payload;
    },
    //when logout remove the user info
    removeUserInfo: (state) => {
      state = initialState;
    },
  },
});

// export my actions to dispatch hook
export const { setUserInfo, removeUserInfo } = userInfoSlice.actions;

// get my user info object from my selector hook
export const selectUserInfo = (state: RootState) => state.userInfo;
// permissions
export const selectPermissions = (state: RootState) => state.userInfo.permissions;

// export reducer to my state
export default userInfoSlice.reducer;
