import axiosInstance from '../../../Core/AxiosInstance/axiosInstance';

export const userLogin = async (
  eventHash: any,
  data: {
    username: string;
    // password: string;
  }
): Promise<any> => {
  const res: any = await axiosInstance.post(`/auth/events/${eventHash}/login`, data);
  return res.data.data;
};
