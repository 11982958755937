import axiosInstance from '../../Core/AxiosInstance/axiosInstance';

export const fetchEvents = async (eventHash: string) => {
  const res = await axiosInstance.get(`/events/${eventHash}`);
  return res.data.data;
};

export const fetchPages = async (eventHash: string) => {
  const res = await axiosInstance.get(`/events/${eventHash}/pages`);
  return res.data.data;
};

export const verifyHash = async (eventHash: string) => {
  const res = await axiosInstance.get(`/verify-hash/${eventHash}`);
  return res.data.data;
};
