import styled from 'styled-components';

export const Root = styled.div`
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  img: {
    width: '350px';
    opacity: '.8';
  }
`;
