import { createSlice } from '@reduxjs/toolkit';
import { RootState } from '../../../Core/Store/store';

//initial state for spinner info
const initialState: { spin: boolean } = {
  spin: false,
};

/**
 * @todo
 * create my reducer && set actions to it
 *
 */
export const spinnerSlice = createSlice({
  name: 'spinner',
  initialState,

  reducers: {
    //to show spinner or close it
    spinState: (state, action) => {
      state = action.payload;
    },
  },
});

// export my actions to dispatch hook
export const { spinState } = spinnerSlice.actions;

// get my spinner object from my selector hook
export const selectSpinnerState = (state: RootState) => state.spinner.spin;

// export reducer to my state
export default spinnerSlice.reducer;
